import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-book_svg__a)">
      <path
        d="M18.669 30.092c0-2.39 1.607-4.385 3.793-5.02H7.571c-.365 0-.719-.043-1.062-.116a5.07 5.07 0 0 0 1.062 10.027h15.988a4.891 4.891 0 0 1-4.891-4.89h.001Z"
        fill="#FFC33A"
      />
      <path
        d="M23.031 26.901a3.107 3.107 0 0 0-2.347 3.638c.283 1.473 1.658 2.487 3.158 2.487H37.5v-6.203H23.727c-.239 0-.472.027-.696.078Z"
        fill="#E5E8EB"
      />
      <path
        d="M23.559 25.071h-1.097c-2.186.635-3.793 2.63-3.793 5.021a4.891 4.891 0 0 0 4.89 4.891h11.985c1.08 0 1.956-.876 1.956-1.956H23.842c-1.5 0-2.875-1.014-3.158-2.487a3.107 3.107 0 0 1 3.043-3.716H37.5c0-.865-.557-1.592-1.33-1.863a1.945 1.945 0 0 1-.626.112H23.56l-.001-.002Z"
        fill="#FFA300"
      />
      <path
        d="M22.462 25.07h1.097a4.891 4.891 0 0 1-4.891-4.89c0-2.391 1.607-4.385 3.793-5.021H7.571c-.365 0-.719-.042-1.062-.115A5.07 5.07 0 0 0 2.5 20a5.07 5.07 0 0 0 4.009 4.956c.343.073.697.115 1.062.115h14.891Z"
        fill="#3182F6"
      />
      <path
        d="M23.031 16.99a3.106 3.106 0 0 0-2.347 3.638c.283 1.473 1.658 2.487 3.158 2.487H37.5v-6.203H23.727c-.239 0-.472.027-.696.078Z"
        fill="#E5E8EB"
      />
      <path
        d="M23.559 15.16h-1.097c-2.186.635-3.793 2.63-3.793 5.021a4.891 4.891 0 0 0 4.89 4.891h11.985c.22 0 .429-.045.627-.112a1.949 1.949 0 0 0 1.329-1.844H23.842c-1.5 0-2.875-1.014-3.158-2.487a3.106 3.106 0 0 1 3.043-3.716H37.5c0-.865-.557-1.593-1.33-1.863a1.945 1.945 0 0 1-.626.112H23.56l-.001-.002Z"
        fill="#1E6EF4"
      />
      <path
        d="M22.462 15.16h1.097a4.891 4.891 0 0 1-4.891-4.89c0-2.778 2.162-5.027 4.891-5.216v-.036H7.571a5.07 5.07 0 1 0 0 10.142h14.891Z"
        fill="#23B169"
      />
      <path
        d="M23.031 7.078a3.106 3.106 0 0 0-2.056 1.591c-.023.044-.034.095-.054.14a3.067 3.067 0 0 0-.293 1.178c-.001.037.009.077.009.114 0 .202.008.406.048.615.283 1.473 1.658 2.487 3.158 2.487h13.658V7H23.728c-.239 0-.473.027-.697.078Z"
        fill="#E5E8EB"
      />
      <path
        d="M37.5 13.204H23.842c-1.5 0-2.875-1.014-3.158-2.487a3.232 3.232 0 0 1-.048-.615c0-.037-.01-.077-.009-.114.015-.422.126-.816.293-1.178.021-.045.031-.096.054-.14a3.106 3.106 0 0 1 2.752-1.669h13.773a1.982 1.982 0 0 0-1.982-1.982H23.92c-.124 0-.239.028-.361.036-2.729.189-4.891 2.438-4.891 5.215a4.891 4.891 0 0 0 4.891 4.891h11.984c.221 0 .429-.045.627-.112a1.949 1.949 0 0 0 1.329-1.844l.001-.001Z"
        fill="#03905A"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-book_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryBook;

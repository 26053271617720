import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryHealth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-health_svg__a)">
      <path
        d="M21.791 19.044c-.13-.149-.25-.298-.374-.448-.003.092-.01.19-.017.284.133.045.261.105.391.164ZM34.214 24.201a5.992 5.992 0 0 1-1.647 5.384 5.946 5.946 0 0 1-3.44 1.68c1.864.018 3.498-.479 4.702-1.557.074-.067.158-.12.229-.192 1.432-1.432 1.954-3.55 1.693-5.972-.133.085-.27.17-.43.25a5.74 5.74 0 0 1-1.107.407ZM9.68 10.413c.044-.02.078-.052.122-.072.317-.148.643-.25.97-.325.746-1.592 2.493-4.74 4.448-4.17.213.061.402.166.58.287a4.038 4.038 0 0 1 1.716-.993c-2.501-.31-4.695.2-6.164 1.668-.071.071-.126.155-.192.23-.814.907-1.296 2.062-1.478 3.374l-.001.001Z"
        fill="#FF9C00"
      />
      <path
        d="M38.263 15.436c-1.256-2.041-5.337-1.962-5.337-1.962s1.138-1.687-1.216-3.414c-1.014-.744-2.202-.729-3.226-.474.791.138 1.55.487 2.161 1.098a4.073 4.073 0 0 1 0 5.761c-.056.056-.12.094-.179.146 1.2 1.637 1.942 3.252 2.162 4.587a5.964 5.964 0 0 1 1.585 3.022 5.744 5.744 0 0 0 1.107-.408c.16-.08.297-.165.43-.25 2.482-1.594.513-4.065.513-4.065s3.256-2.002 2-4.043v.002ZM16.09 12.63a4.713 4.713 0 0 1 .306 3.124 3.652 3.652 0 0 1 1.624 1.689c.224.479.329.981.338 1.478a4.575 4.575 0 0 1 3.043-.041c.006-.095.014-.192.017-.284.034-1.021-.096-1.891-.331-2.63-.567.102-1.148.076-1.703-.138-1.835-.709-2.652-3.017-1.826-5.156l.047-.108C17.46 9.072 17.03 6.97 15.8 6.132a2.056 2.056 0 0 0-.58-.288c-1.954-.569-3.701 2.579-4.446 4.171a4.73 4.73 0 0 1 5.317 2.614v.001Z"
        fill="#036D50"
      />
      <path
        d="M19.384 15.83c.554.213 1.135.24 1.702.137 1.312-.236 2.54-1.233 3.117-2.726.826-2.138.347-5.323-1.488-6.032-1.804-.697-4.242 1.258-5.11 3.356-.015.036-.033.072-.047.108-.826 2.138-.008 4.447 1.826 5.156Zm-.615-4.689a2.204 2.204 0 1 1 4.113 1.59 2.204 2.204 0 0 1-4.114-1.59h.001Z"
        fill="#FFFEFA"
      />
      <path
        d="M24.086 21.104c-.028.028-.045.061-.072.09.209.436.344.9.409 1.371.32.035.638.096.95.184.056-.064.1-.136.16-.197a3.95 3.95 0 0 1 5.587 5.586c-.452.452-.99.755-1.56.942a5.78 5.78 0 0 1-.718 2.172c.095.003.192.012.286.013 1.257-.169 2.474-.713 3.44-1.68a5.992 5.992 0 0 0 1.647-5.384 5.968 5.968 0 0 0-1.585-3.022c-.023-.024-.038-.052-.061-.075a5.997 5.997 0 0 0-8.481 0h-.002Z"
        fill="#D12030"
      />
      <path
        d="M29.561 29.08a3.909 3.909 0 0 0 1.559-.942 3.95 3.95 0 0 0-5.586-5.586c-.061.061-.104.133-.161.197a5.802 5.802 0 0 1 2.545 1.49c1.322 1.322 1.854 3.12 1.643 4.841Z"
        fill="#F56570"
      />
      <path
        d="M32.57 21.1c.02.03.04.06.06.08-.22-1.34-.96-2.95-2.16-4.59.05-.05.12-.09.18-.14a4.086 4.086 0 0 0 0-5.77c-.61-.61-1.37-.96-2.16-1.09-.14-.03-.27-.06-.4-.07.66-1.49.38-3.29-.84-4.52a4.074 4.074 0 0 0-5.76 0c-.21.22-.39.45-.55.69-1.02-.7-2.28-.88-3.42-.55-.63.18-1.22.51-1.72.99 1.23.84 1.66 2.94 1.81 4.44.86-2.1 3.3-4.06 5.11-3.36 1.83.71 2.31 3.89 1.48 6.03-.57 1.49-1.8 2.49-3.11 2.73.23.74.36 1.61.33 2.63.12.15.24.3.37.44-.13-.05-.26-.11-.39-.16-.98-.34-2.06-.32-3.04.04-.01-.5-.12-1-.34-1.48-.35-.76-.94-1.32-1.62-1.69.24-1.01.17-2.1-.31-3.12a4.723 4.723 0 0 0-5.32-2.61c-.32.07-.65.17-.97.32-.04.02-.08.05-.12.07-.56 4.07 1.8 9.68 6.45 14.33.17.17.35.33.53.5 3.95 3.74 8.49 5.87 12.18 6.01.39-.68.63-1.41.72-2.17.21-1.72-.32-3.52-1.64-4.84a5.888 5.888 0 0 0-2.55-1.49c-.31-.09-.63-.15-.95-.19-.06-.47-.2-.93-.41-1.37.03-.03.05-.06.08-.09a6 6 0 0 1 8.48 0Zm-4.89-5.11.24-.47c.18-.35.6-.48.95-.31l.47.25c.35.17.48.6.3.95l-.24.46a.7.7 0 0 1-.95.31l-.47-.24a.707.707 0 0 1-.3-.95Zm-4.85 3.86a.707.707 0 0 1-.9-.44l-.12-.36-.05-.14c-.13-.37.07-.77.43-.89l.5-.18c.37-.12.77.07.9.44l.17.5c.13.37-.06.77-.43.9l-.37.12-.13.05Z"
        fill="#23B169"
      />
      <path
        d="M20.03 13.991a2.204 2.204 0 1 0 1.59-4.111 2.204 2.204 0 0 0-1.59 4.111ZM27.919 15.522l-.24.469a.706.706 0 0 0 .306.95l.469.24c.347.178.772.04.95-.306l.24-.47a.706.706 0 0 0-.306-.95l-.47-.24a.706.706 0 0 0-.95.307ZM21.931 19.412a.706.706 0 0 0 .898.436l.129-.045.369-.128a.706.706 0 0 0 .436-.898l-.173-.499a.706.706 0 0 0-.898-.436l-.499.173a.705.705 0 0 0-.436.898l.047.137.125.362h.002Z"
        fill="#FFD900"
      />
      <path
        d="M29.128 31.265c-.094 0-.191-.009-.286-.013-3.695-.138-8.236-2.268-12.181-6.012-.177-.168-.358-.328-.532-.502-4.65-4.649-7.013-10.255-6.448-14.326.182-1.312.664-2.467 1.478-3.375l.029-.066-2.91 2.91c-6.27 6.271-6.27 16.437 0 22.707s16.437 6.27 22.707 0l2.91-2.91-.066.03c-1.204 1.077-2.838 1.574-4.702 1.556v.001Z"
        fill="#FFC450"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-health_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryHealth;

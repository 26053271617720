import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryCulture = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-culture_svg__a)">
      <path
        d="M35.041 12.111H27.89v2.834c0 7.15-5.796 12.945-12.945 12.945-.882 0-1.744-.09-2.576-.258 1.195 5.915 6.42 10.37 12.687 10.37 7.15 0 12.945-5.797 12.945-12.946V15.07a2.96 2.96 0 0 0-2.959-2.959ZM30.16 30.5c-1.035-.348-2.767-.766-5.104-.766s-4.069.418-5.104.766c-.472.158-.837-.427-.486-.779 1.032-1.036 3.202-1.929 5.59-1.929s4.56.893 5.59 1.93c.351.352-.014.937-.486.778Zm1.02-6.857a4.729 4.729 0 0 0-2.09-.472c-.947 0-1.667.263-2.09.472a.261.261 0 0 1-.373-.284c.24-1.176 1.251-2.059 2.463-2.059 1.212 0 2.224.883 2.463 2.06a.262.262 0 0 1-.373.283Z"
        fill="#64A7FF"
      />
      <path
        d="M25.055 27.79c-2.388 0-4.559.893-5.59 1.929-.351.353.014.938.486.779 1.035-.348 2.767-.766 5.104-.766s4.069.418 5.104.766c.472.158.837-.427.486-.779-1.032-1.036-3.202-1.929-5.59-1.929Z"
        fill="#2F81F9"
      />
      <path
        d="M27.889 14.945V4.959A2.96 2.96 0 0 0 24.93 2H4.959A2.96 2.96 0 0 0 2 4.959v9.986c0 6.267 4.454 11.492 10.369 12.687.833.168 1.694.258 2.576.258 7.149 0 12.944-5.796 12.944-12.945Zm-8.91-3.757c1.212 0 2.224.883 2.463 2.059a.262.262 0 0 1-.373.284 4.729 4.729 0 0 0-2.09-.472c-.947 0-1.667.263-2.09.472a.261.261 0 0 1-.373-.284c.239-1.176 1.251-2.059 2.463-2.059ZM8.447 13.247c.239-1.176 1.251-2.059 2.463-2.059 1.212 0 2.224.883 2.463 2.059a.262.262 0 0 1-.373.284 4.729 4.729 0 0 0-2.09-.472c-.947 0-1.667.263-2.09.472a.261.261 0 0 1-.373-.284Zm.907 5.239c-.351-.353.014-.938.486-.779 1.035.348 2.767.766 5.104.766s4.069-.418 5.104-.766c.472-.158.837.427.486.779-1.032 1.036-3.202 1.929-5.59 1.929s-4.559-.893-5.59-1.929Z"
        fill="#FFC84D"
      />
      <path
        d="M29.09 21.298c-1.212 0-2.224.883-2.463 2.059a.262.262 0 0 0 .373.284 4.729 4.729 0 0 1 2.09-.472c.947 0 1.666.263 2.09.472a.261.261 0 0 0 .372-.284c-.238-1.176-1.25-2.059-2.462-2.059ZM13 13.531a.261.261 0 0 0 .373-.284c-.24-1.176-1.251-2.059-2.463-2.059-1.212 0-2.224.883-2.463 2.059a.262.262 0 0 0 .373.284 4.729 4.729 0 0 1 2.09-.472c.947 0 1.667.263 2.09.472ZM16.516 13.247a.262.262 0 0 0 .373.284 4.729 4.729 0 0 1 2.09-.472c.947 0 1.667.263 2.09.472a.261.261 0 0 0 .373-.284c-.239-1.176-1.25-2.059-2.463-2.059-1.212 0-2.224.883-2.463 2.059Z"
        fill="#000"
      />
      <path
        d="M20.049 17.706c-1.035.348-2.767.766-5.104.766s-4.07-.418-5.104-.766c-.472-.158-.837.427-.486.779 1.032 1.036 3.202 1.929 5.59 1.929s4.559-.893 5.59-1.929c.35-.353-.014-.938-.486-.779Z"
        fill="#F80"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-culture_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryCulture;

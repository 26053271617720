import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryDesign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-design_svg__a)">
      <path
        d="M37.564 21.064c0-9.986-8.334-18.026-18.427-17.543-8.81.42-16.096 7.577-16.663 16.38-.44 6.828 3.027 12.879 8.38 16.15 1.797 1.098 4.15.083 4.686-1.953l.066-.25h.007a4.596 4.596 0 0 1 4.514-3.747 4.596 4.596 0 0 1 4.514 3.747h.007c.478 2.12 2.92 3.205 4.754 2.04 4.904-3.113 8.162-8.585 8.162-14.824Z"
        fill="#FFAD00"
      />
      <path
        d="M20 12.412a2.797 2.797 0 1 0 0-5.594 2.797 2.797 0 0 0 0 5.594Z"
        fill="#FFDA16"
      />
      <path
        d="M12.171 15.655a2.797 2.797 0 1 0 0-5.594 2.797 2.797 0 0 0 0 5.594Z"
        fill="#F77F00"
      />
      <path
        d="M8.928 23.484a2.797 2.797 0 1 0 0-5.594 2.797 2.797 0 0 0 0 5.594Z"
        fill="#EF4452"
      />
      <path
        d="M27.83 15.655a2.797 2.797 0 1 0 0-5.594 2.797 2.797 0 0 0 0 5.594Z"
        fill="#4FC578"
      />
      <path
        d="M31.071 23.484a2.797 2.797 0 1 0 0-5.594 2.797 2.797 0 0 0 0 5.594Z"
        fill="#1E6EF4"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-design_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryDesign;

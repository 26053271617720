import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoKeywordCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 72 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m62.193 4.828 5.225 3.012M66.313 3.725l-3.016 5.218"
      stroke="#080808"
      strokeWidth={1.582}
      strokeLinecap="round"
    />
    <path
      d="M46.32 32.249a1.534 1.534 0 0 1-2.796 1.257 1.534 1.534 0 0 1 2.796-1.257Z"
      fill="#080808"
      stroke="#080808"
      strokeWidth={1.582}
    />
    <path
      d="m3.566 4.335 6.48 7.16M11.751 3.457l1.29 6.004M1.867 12.378l5.852 1.886"
      stroke="#080808"
      strokeWidth={1.582}
      strokeLinecap="round"
    />
    <path
      d="M12.511 18.627a3.955 3.955 0 0 1 3.844-3.015h33.532c2.565 0 4.451 2.399 3.844 4.887l-7.437 30.465a3.955 3.955 0 0 1-3.843 3.014H8.918c-2.564 0-4.45-2.398-3.844-4.886l7.437-30.465Z"
      fill="#080808"
      stroke="#080808"
      strokeWidth={1.582}
    />
    <path
      d="M13.562 20.648c-.722-2.523 1.175-5.036 3.804-5.036h33.477a3.955 3.955 0 0 1 3.804 2.866l8.716 30.465c.722 2.523-1.176 5.035-3.804 5.035H26.082a3.955 3.955 0 0 1-3.804-2.865l-8.716-30.465Z"
      fill="#FFFAF3"
      stroke="#080808"
      strokeWidth={1.582}
    />
    <path
      d="M20.345 21.093a1.645 1.645 0 1 0-1.647-1.644c0 .908.737 1.644 1.647 1.644ZM29.679 21.093a1.645 1.645 0 1 0-1.647-1.644c0 .908.737 1.644 1.647 1.644ZM38.459 21.093a1.645 1.645 0 1 0-1.647-1.644c0 .908.738 1.644 1.647 1.644ZM47.24 21.093a1.645 1.645 0 1 0-1.646-1.644c0 .908.737 1.644 1.646 1.644Z"
      fill="#080808"
    />
    <path
      d="M13.562 20.648c-.722-2.523 1.175-5.036 3.804-5.036h33.477a3.955 3.955 0 0 1 3.804 2.866l8.716 30.465c.722 2.523-1.176 5.035-3.804 5.035H26.082a3.955 3.955 0 0 1-3.804-2.865l-8.716-30.465Z"
      stroke="#080808"
      strokeWidth={1.582}
    />
    <path
      d="M20.349 19.45a3.846 3.846 0 0 0 3.55-2.369 3.832 3.832 0 0 0-.834-4.18 3.843 3.843 0 0 0-6.558 2.712M29.68 19.45a3.846 3.846 0 0 0 3.55-2.369 3.832 3.832 0 0 0-.834-4.18 3.844 3.844 0 0 0-6.558 2.712M39.007 19.45a3.846 3.846 0 0 0 3.55-2.369 3.833 3.833 0 0 0-2.8-5.23 3.846 3.846 0 0 0-3.944 1.63 3.833 3.833 0 0 0-.648 2.132M48.338 19.45a3.845 3.845 0 0 0 3.55-2.369 3.831 3.831 0 0 0-.833-4.18 3.844 3.844 0 0 0-6.559 2.712"
      stroke="#080808"
      strokeWidth={1.582}
      strokeLinecap="round"
    />
    <path
      d="M59.18 45.523c6.991 0 12.66-5.66 12.66-12.642 0-6.982-5.669-12.642-12.66-12.642-6.992 0-12.66 5.66-12.66 12.642 0 6.982 5.668 12.642 12.66 12.642Z"
      fill="#4403B5"
    />
    <path
      d="M58.89 25.375v7.559h8.044"
      stroke="#fff"
      strokeWidth={1.582}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoKeywordCalendar;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.825 9 5.6 5.6L8 16 0 8l8-8 1.425 1.4-5.6 5.6H16v2H3.825Z"
      fill="#57616B"
    />
  </svg>
);
export default SvgIcoBack;

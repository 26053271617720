import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryEnt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-ent_svg__a)">
      <path
        d="m25.261 10.103 1.398-1.626a1.49 1.49 0 0 1-.547-.828c-.224-.85.21-1.782.97-2.083.71-.28 1.446.095 1.722.84l3.044-1.752.003-.002c-.039-.068-.094-.122-.136-.187a4.252 4.252 0 0 0-.39-.536c-.063-.07-.137-.125-.203-.19a4.33 4.33 0 0 0-.459-.409c-.085-.063-.179-.112-.268-.168a4.371 4.371 0 0 0-.49-.277c-.106-.049-.217-.085-.327-.126a4.22 4.22 0 0 0-.512-.159c-.05-.012-.098-.036-.149-.046-.075-.015-.149-.01-.224-.02a4.22 4.22 0 0 0-.845-.038 4.197 4.197 0 0 0-.816.125 4.144 4.144 0 0 0-.787.283 4.53 4.53 0 0 0-.489.266c-.078.05-.15.105-.226.16a4.589 4.589 0 0 0-.446.365c-.064.061-.12.127-.18.192a4.23 4.23 0 0 0-.358.431c-.062.087-.115.18-.17.272a4.1 4.1 0 0 0-.238.438c-.052.113-.093.231-.135.35-.052.147-.1.296-.137.451-.013.054-.037.102-.048.157-.024.125-.034.251-.048.376-.005.05-.015.1-.019.148-.017.243-.012.484.011.723.007.068.02.136.028.204.03.2.071.395.126.587.016.055.028.112.046.167a4.26 4.26 0 0 0 .396.85c.117.197.248.386.397.564.023.027.042.057.065.084.124.141.258.273.4.398.01.01.018.02.028.027l.01-.012.003.001Z"
        fill="#FF9000"
      />
      <path
        d="M23.382 23.253s.501-.707 1.123-1.798c.705-1.237 1.565-2.97 2.045-4.774.297-1.118.447-2.26.32-3.32a4.013 4.013 0 0 0-.24-.95c-.323-.847-.924-1.558-1.73-1.946a3.082 3.082 0 0 0-.574-.215 2.782 2.782 0 0 0-.786-.087 2.089 2.089 0 0 1-.127 4.16l-3.72.215c-1.425 2.306-2.95 4.784-2.952 4.787l6.641 3.928Z"
        fill="#3E5AF4"
      />
      <path
        d="M31.87 4.69c-.01-.02-.02-.03-.02-.04L28.8 6.4c-.27-.74-1.01-1.12-1.72-.83-.76.3-1.19 1.23-.97 2.08.1.36.3.64.55.83l-1.4 1.62-.01.01c-.01 0-.02-.01-.03-.02l-.32.37c.81.39 1.41 1.1 1.73 1.95l1.08-1.26c.85.07 1.72-.1 2.51-.54a4.345 4.345 0 0 0 1.65-5.92Zm-.33 2.8c-.07.37-.3.64-.51.6-.21-.04-.32-.38-.25-.74.07-.37.3-.64.51-.6.21.04.32.37.25.74ZM28.972 30.384a6.08 6.08 0 0 0-2.485-4.85l-1.353-.988-2.606 3.92 1.185.865c.345.252.554.658.556 1.087l.025 3.528h4.702l-.025-3.56.001-.002ZM12.104 26.373l-.713.896a1.04 1.04 0 0 1-1.295.273l-2.06-1.079c-.01-.006-.022-.008-.033-.013L5.87 30.637l2.045 1.071a5.748 5.748 0 0 0 7.155-1.51l1.112-1.397-4.113-2.373.036-.054v-.001ZM25.38 12.11a2.1 2.1 0 0 1-1.97 2.21l-3.72.22-.57.03c-.99.06-1.9.58-2.44 1.41l-.63.96c.12.31.19.63.19.98 0 1.44-1.16 2.61-2.61 2.61-1.44 0-2.61-1.17-2.61-2.61 0-.49.15-.94.38-1.33.05-.18.13-.37.24-.54l1.55-2.36a7.317 7.317 0 0 1 5.69-3.29l4.29-.25a2.095 2.095 0 0 1 2.21 1.96ZM35.65 23.8l-2.94.58c-.74.15-1.22.87-1.07 1.61.12.62-.34 1.23-1 1.25-.52.02-.97-.39-1.07-.9l-.11-.59a3.04 3.04 0 0 0-.39-.97l-2.05-1.2c-.96-.57-1.8-1.29-2.52-2.13.71-1.23 1.57-2.97 2.05-4.77l.12.32c.45 1.25 1.33 2.3 2.47 2.97l1.85 1.09c.55.24 2.35.94 4.26.69.5-.07 1.02.2 1.18.68.22.62-.17 1.25-.78 1.37Z"
        fill="#FFC84D"
      />
      <path
        d="m25.137 24.542-1.755-1.29-6.642-3.927-4.636 7.047-.036.054 4.113 2.373 1.772-2.165a1.219 1.219 0 0 1 1.648-.223l2.917 2.066.01-.014 2.605-3.919.002-.003.002.001ZM31.454 35.497l-2.43-1.552h-4.736v4.053c0 .235.19.425.425.425h7.393c.235 0 .425-.19.425-.425v-.536a2.33 2.33 0 0 0-1.077-1.965ZM8.01 26.436l-3.613-1.84a.425.425 0 0 0-.572.186L.47 31.37a.425.425 0 0 0 .186.572l.478.243a2.333 2.333 0 0 0 2.24-.067l2.486-1.46.01-.02 2.133-4.187.007-.014Z"
        fill="#4E5968"
      />
      <path
        d="M35.406 31.475c.144.303.235.617.278.938.283-.079.549-.179.793-.297l-2.7-5.748v-.006c.1-.039.206-.065.309-.096l2.676 5.7c.266-.153.507-.324.723-.504-.846-.867-1.693-2.46-1.053-5.194a4.058 4.058 0 0 0-1.178-.193 4.092 4.092 0 0 0-3.742 2.443c.808.282 3.058 1.202 3.892 2.956l.002.001Z"
        fill="#FF9000"
      />
      <path
        d="M37.717 31.26a5.992 5.992 0 0 0 1.429-2.064c.01-.028.036-.044.058-.06a4.087 4.087 0 0 0-2.46-2.77c-.605 2.62.187 4.102.973 4.894ZM35.093 34.252c.002-.016-.006-.03 0-.047.204-.482.3-.949.294-1.399a5.564 5.564 0 0 1-1.122.111c-.703 0-1.498-.13-2.382-.427-.08-.118-.143-.249-.212-.375.028-.006.057-.014.086-.004 1.44.525 2.626.582 3.597.381a2.824 2.824 0 0 0-.239-.879c-.77-1.623-2.942-2.516-3.71-2.789.036-.103.065-.207.11-.306a4.073 4.073 0 0 0-.355 1.653 4.096 4.096 0 0 0 4.096 4.096c.023 0 .045-.006.07-.007-.024.003-.046.007-.07.007-.056 0-.108-.014-.164-.016l.001.001ZM39.354 30.172c0-.184-.031-.36-.054-.538a6.565 6.565 0 0 1-1.361 1.833c.468.403.885.57.93.586.003 0 .003.003.005.004-.05.095-.097.192-.154.283-.096-.039-.52-.227-1.01-.662a5.379 5.379 0 0 1-.816.563l.629 1.34c-.023.016-.05.027-.074.04a4.083 4.083 0 0 0 1.904-3.45v.001ZM35.71 32.726c.017.492-.084 1.002-.295 1.527a4.052 4.052 0 0 0 1.823-.516l-.631-1.344a5.09 5.09 0 0 1-.897.333Z"
        fill="#FF9000"
      />
      <path
        d="M36.893 32.242a5.33 5.33 0 0 0 .816-.563c.49.435.914.622 1.01.662.057-.09.104-.188.154-.283-.002 0-.002-.003-.004-.004a3.402 3.402 0 0 1-.93-.586 6.58 6.58 0 0 0 1.36-1.833 4.104 4.104 0 0 0-.095-.5c-.022.016-.047.032-.058.06a6.009 6.009 0 0 1-1.429 2.064c-.785-.792-1.578-2.275-.972-4.895-.1-.039-.206-.065-.31-.096-.64 2.734.207 4.327 1.053 5.194a5.08 5.08 0 0 1-.723.503l-2.676-5.699c-.104.031-.209.057-.309.096v.006l2.7 5.748c-.244.118-.51.218-.793.297a3.169 3.169 0 0 0-.278-.938c-.834-1.753-3.084-2.673-3.892-2.956-.044.1-.073.203-.109.306.768.273 2.938 1.166 3.71 2.79.134.281.202.577.24.878-.972.2-2.159.143-3.598-.38-.029-.012-.057-.003-.086.003.07.127.13.257.212.375.884.297 1.68.427 2.382.427.403 0 .776-.04 1.122-.11.005.45-.09.916-.294 1.398-.007.015.001.031 0 .047.056.002.108.016.164.016.023 0 .046-.004.07-.007.03-.004.06-.008.09-.009.21-.525.311-1.035.294-1.527a5.09 5.09 0 0 0 .897-.334l.631 1.344.21-.114c.025-.014.051-.025.074-.04l-.629-1.34h-.004Z"
        fill="#EC6700"
      />
      <path
        d="M31.03 8.086c.21.041.438-.225.51-.593.072-.368-.04-.7-.248-.741-.21-.041-.437.225-.51.593-.071.368.04.7.249.741Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-ent_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryEnt;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryTrend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-trend_svg__a)">
      <path
        d="M31.22 11.07c.409 2.729.884 4.291-1.068 4.291-2.656 0-2.657-6.286-7.152-9.867-4.447-3.542-7.764-2.681-7.834-2.662l-.004.004c3.704 3.092 1.638 8.174-.786 9.575-2.326 1.344-4.981-.351-3.591-4.34-3.057 4.18-5.696 9.245-5.696 14.292 0 8.235 6.676 14.911 14.91 14.911 8.236 0 14.912-6.676 14.912-14.911 0-3.891-1.572-7.793-3.691-11.293Z"
        fill="#EF4452"
      />
      <path
        d="M20 20.018s6.484 3.734 6.484 8.951a6.484 6.484 0 0 1-12.968 0c0-5.217 6.484-8.95 6.484-8.95Z"
        fill="#FF7978"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-trend_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryTrend;

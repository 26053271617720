import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryIt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-it_svg__a)">
      <path
        d="M4.66 5.43c-.883 0-1.598.717-1.598 1.599v21.9c0 .884.716 1.599 1.598 1.599h.93L30.69 5.43H4.658Z"
        fill="#333D4B"
      />
      <path
        d="M35.463 5.43h-4.774L5.59 30.53h29.872c.883 0 1.598-.715 1.598-1.598V7.03c0-.883-.715-1.6-1.598-1.6Z"
        fill="#333D4B"
      />
      <path
        d="M24.843 30.922v-1.67H15.28v1.67c0 1.628-.83 3.144-2.202 4.02l-1.18.756c-.455.29-.25.995.29.995h15.748c.54 0 .745-.704.291-.995l-1.18-.755a4.772 4.772 0 0 1-2.203-4.021Z"
        fill="#8A94A0"
      />
      <path
        d="M27.89 35.518H12.233a.587.587 0 1 0 0 1.174H27.89a.587.587 0 1 0 0-1.174ZM4.66 30.529h30.802c.883 0 1.598-.716 1.598-1.598v-2.738H3.063v2.738c0 .883.715 1.598 1.598 1.598Z"
        fill="#AFB7C0"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-it_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryIt;

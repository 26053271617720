import { TeambinderApiService } from '@src/api';
import {
  KAKAO_REDIRECT_URL_COMMAND,
  PUBLIC_URL,
} from '@src/constants/constant';
import useLogout from '@src/hooks/auth/useLogout';
import { themeCurrentThemeAtom } from '@src/store/theme';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, Modal, Progress, Radio, Switch } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const { confirm, info } = Modal;

const TabInfo = () => {
  const { data: userResponse, refetch: refetchSetting } = useQuery(
    ['member'],
    TeambinderApiService.getPersonalSettings,
  );
  const [normalizedPhoneNumber, setNormalizedPhoneNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    if (userResponse?.data?.notification?.email?.email) {
      setEmail(userResponse?.data?.notification?.email?.email);
    }
    if (userResponse?.data?.notification?.kakao?.mobile) {
      const phone = userResponse?.data?.notification?.kakao?.mobile;
      const normalized = '0' + phone.split(/[- ]/).slice(1).join(''); // "+82 10-3333-5555" -> "01033335555"
      setPhoneNumber(normalized);
      setNormalizedPhoneNumber(normalized);
    }
  }, [userResponse?.data]);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);
  const handleClickSetTheme = () => {
    if (currentTheme === 'dark') {
      setCurrentTheme('light');
    } else {
      setCurrentTheme('dark');
    }
  };
  const _logout = useLogout();
  const logout = async () => {
    _logout();
    window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=7fa35d903c130394963af980d96edd38&logout_redirect_uri=${PUBLIC_URL}&prompt=login`;
  };

  const navigate = useNavigate();
  const { data: currentSubscription } = useQuery(
    ['current-subscription'],
    () => TeambinderApiService.getLatestSubscription({ q: 'latest' }),
    { refetchInterval: 3000 },
  );

  // TODO: openapi 스펙 이상함
  const currentPlan =
    // @ts-ignore
    currentSubscription?.data?.plan?.subscriptionPackage?.subscriptionPlan;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
        paddingLeft: 0,
        fontSize: '16px',
      }}
    >
      <div>
        <h4 style={{ display: 'inline-block' }}>
          카카오로 알림 받기
          {userResponse?.data?.notification?.kakao?.mobile && (
            <Switch
              checked={userResponse?.data?.notification?.kakao?.enabled}
              style={{ marginLeft: '10px' }}
              onClick={async (checked) => {
                await TeambinderApiService.modifyPersonalSettings({
                  settingName: 'notification.kakao.enabled',
                  value: String(checked),
                });
                refetchSetting();
              }}
            />
          )}
        </h4>
        <div>
          설정한 키워드에 새로운 소식이 생기면 카카오 알림톡으로 알려드립니다.
          <br />
          {!userResponse?.data?.hasPhoneNumber && (
            <>
              등록된 전화번호가 없으면 카카오 알림톡 발송이 불가합니다.
              <br />
              <Input.Search
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                enterButton={
                  <Button disabled={phoneNumber === normalizedPhoneNumber}>
                    저장
                  </Button>
                }
                placeholder="핸드폰 번호"
                style={{ marginTop: '12px', maxWidth: '460px' }}
                onSearch={async (phone) => {
                  if (!/^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/.test(phone)) {
                    alert('핸드폰 번호 형식이 올바르지 않습니다.');
                    return;
                  }
                  await TeambinderApiService.modifyPhoneNumber({
                    requestBody: { phoneNumber: phone },
                  });
                  refetchSetting();
                }}
              />
            </>
          )}
          {!userResponse?.data?.notification?.kakao?.mobile &&
            userResponse?.data?.hasPhoneNumber && (
              <>
                등록된 전화번호가 없으면 카카오 알림톡 발송이 불가합니다. <br />
                <Button size="small" type="link" style={{ padding: 0 }}>
                  <a
                    href={`https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${encodeURI(
                      KAKAO_REDIRECT_URL_COMMAND,
                    )}&response_type=code`}
                  >
                    핸드폰 번호 등록하기
                  </a>
                </Button>
              </>
            )}
        </div>
      </div>
      <div>
        <h4 style={{ display: 'inline-block' }}>
          이메일로 알림 받기
          <Switch
            checked={userResponse?.data?.notification?.email?.enabled}
            style={{ marginLeft: '10px' }}
            onClick={async (checked) => {
              await TeambinderApiService.modifyPersonalSettings({
                settingName: 'notification.email.enabled',
                value: String(checked),
              });
              refetchSetting();
            }}
          />
        </h4>
        <div>
          설정한 키워드에 새로운 소식이 생기면 이메일로 알려드립니다.
          <br />
          <Input.Search
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            enterButton={
              <Button
                disabled={
                  email ===
                  (userResponse?.data?.notification?.email?.email || '')
                }
              >
                저장
              </Button>
            }
            placeholder="이메일 주소"
            style={{ marginTop: '12px', maxWidth: '460px' }}
            onSearch={async (email) => {
              if (
                !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/.test(
                  email,
                )
              ) {
                alert('이메일 형식이 올바르지 않습니다.');
                return;
              }
              await TeambinderApiService.modifyEmail({
                requestBody: { email },
              });
              refetchSetting();
            }}
          />
        </div>
      </div>
      <div
        role="presentation"
        style={{
          gap: '30px',
        }}
      >
        <h4 style={{ display: 'inline-block' }}>알림톡 발송 빈도</h4>
        {currentPlan !== 'PREMIUM' && (
          <div style={{ marginBottom: '10px' }}>
            구독 중인 요금제에 따라 알림톡 발송 빈도가 제한됩니다.
          </div>
        )}
        <div>
          <div
            style={{
              width: '100%',
              maxWidth: '460px',
              marginBottom: '16px',
            }}
          >
            <div>
              <Radio.Group
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                onChange={async (e) => {
                  if (
                    !userResponse?.data?.notification?.kakao?.intervalOption
                      ?.availableOptions?.[e.target.value]
                  ) {
                    confirm({
                      title: (
                        <div>
                          구독 중인 요금제에서 이용할 수 없는 옵션입니다.
                          <br />
                          나에게 맞는 플랜을 찾아보세요!
                        </div>
                      ),
                      okText: '플랜 보기',
                      onOk() {
                        window.location.href = '/app/my?tab=2';
                      },
                      cancelText: '취소',
                    });
                    return;
                  }

                  await TeambinderApiService.modifyPersonalSettings({
                    settingName: 'notification.kakao.interval',
                    value: e.target.value,
                  });
                  refetchSetting();
                }}
                value={
                  userResponse?.data?.notification?.kakao?.intervalOption?.value
                }
              >
                <Radio value={'ONCE_A_WEEK'} style={{ marginRight: 0 }}>
                  일주일 1회
                </Radio>
                <Radio value={'ONCE_A_DAY'} style={{ marginRight: 0 }}>
                  하루 1회
                </Radio>
                <Radio value={'AS_NEEDED'} style={{ marginRight: 0 }}>
                  수시로
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
      <div
        role="presentation"
        style={{
          gap: '30px',
        }}
      >
        <h4 style={{ display: 'inline-block' }}>서비스 설정 현황</h4>
        <div>
          <div
            style={{
              width: '100%',
              maxWidth: '460px',
              marginBottom: '16px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>현재 키워드 설정 숫자</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {userResponse?.data?.quota?.keyword?.used}/
                {userResponse?.data?.quota?.keyword?.total}개
                {currentPlan !== 'PREMIUM' && (
                  <Button
                    onClick={() => {
                      navigate('/app/my?tab=2');
                    }}
                    type="primary"
                    size="small"
                    style={{
                      backgroundColor: '#6268DB',
                      border: '#6268DB',
                      fontSize: '10px',
                      borderRadius: '4px',
                      width: '62px',
                      height: '24px',
                      padding: '2px',
                      marginLeft: '10px',
                    }}
                  >
                    업그레이드
                  </Button>
                )}
              </span>
            </div>
            <Progress
              strokeColor="rgb(48, 197, 134)"
              percent={
                ((userResponse?.data?.quota?.keyword?.used || 0) /
                  (userResponse?.data?.quota?.keyword?.total || 1)) *
                100
              }
              showInfo={false}
              width={300}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              width: '100%',
              maxWidth: '460px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>현재 알림톡 발송 숫자</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {userResponse?.data?.quota?.notification?.total === 999 ? (
                  <span>무제한</span>
                ) : (
                  <>
                    {userResponse?.data?.quota?.notification?.used}/
                    {userResponse?.data?.quota?.notification?.total}개
                    {currentPlan !== 'PREMIUM' && (
                      <Button
                        onClick={() => {
                          navigate('/app/my?tab=2');
                        }}
                        type="primary"
                        size="small"
                        style={{
                          backgroundColor: '#6268DB',
                          border: '#6268DB',
                          fontSize: '10px',
                          borderRadius: '4px',
                          width: '62px',
                          height: '24px',
                          padding: '2px',
                          marginLeft: '10px',
                        }}
                      >
                        업그레이드
                      </Button>
                    )}
                  </>
                )}
              </span>
            </div>
            <Progress
              strokeColor="rgb(48, 197, 134)"
              percent={
                userResponse?.data?.quota?.notification?.total === 999
                  ? 0
                  : ((userResponse?.data?.quota?.notification?.used || 0) /
                      (userResponse?.data?.quota?.notification?.total || 1)) *
                    100
              }
              showInfo={false}
              width={300}
            />
            <div
              style={{
                fontSize: '13px',
                color: '#6B7280',
                textAlign: 'end',
                wordBreak: 'keep-all',
              }}
            >
              알림톡 발송 제한은 한달마다 초기화됩니다. <br />
              (다음 초기화 일자:{' '}
              {dayjs(userResponse?.data?.quota?.resetDate).format('M월 D일')})
            </div>
          </div>
        </div>
      </div>
      <div
        role="presentation"
        style={{
          gap: '30px',
        }}
      >
        <h4 style={{ display: 'inline-block' }}>
          배경화면 테마
          <Switch
            checked={currentTheme === 'dark'}
            style={{ marginLeft: '10px' }}
            onClick={handleClickSetTheme}
          />
        </h4>
      </div>
      <div>
        <h4>문의</h4>
        <a href="mailto:mysignal.bob@gmail.com">mysignal.bob@gmail.com</a>
      </div>
      <div role="presentation" onClick={logout} style={{ cursor: 'pointer' }}>
        로그아웃
      </div>
    </div>
  );
};

export default TabInfo;

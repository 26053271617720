import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryNews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-news_svg__a)">
      <path
        d="M8.332 32.585V6.478c0-1.093.886-1.978 1.978-1.978h25.21c1.093 0 1.978.886 1.978 1.978V32.65a2.849 2.849 0 0 1-2.85 2.849H5.419l2.914-2.914Z"
        fill="#D0D5DA"
      />
      <path d="M12.798 15.196h20.209v-5.188h-20.21v5.188Z" fill="#AFB7C0" />
      <path
        d="M5.416 35.5a2.916 2.916 0 0 1-2.915-2.915v-20.6c0-1.092.886-1.978 1.978-1.978h3.852v22.578A2.916 2.916 0 0 1 5.416 35.5Z"
        fill="#8A94A0"
      />
      <path
        d="M33.008 18.761H12.823v.988h20.185v-.988ZM33.008 22.388H12.823v.988h20.185v-.988ZM33.008 26.016H12.823v.988h20.185v-.988ZM33.008 29.643H12.823v.988h20.185v-.988Z"
        fill="#AFB7C0"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-news_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryNews;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryMarketing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-marketing_svg__a)">
      <path
        d="M17.72 35h4.56c.92 0 1.667-.746 1.667-1.667V22.456c0-.92-.746-1.667-1.667-1.667h-4.56c-.921 0-1.668.746-1.668 1.667v10.877c0 .92.747 1.667 1.667 1.667Z"
        fill="#007FF3"
      />
      <path
        d="M6.667 35h4.56c.921 0 1.668-.746 1.668-1.667V14.562c0-.92-.747-1.667-1.668-1.667h-4.56c-.921 0-1.667.746-1.667 1.667v18.771C5 34.253 5.746 35 6.667 35Z"
        fill="#00BC79"
      />
      <path
        d="M28.772 35h4.561c.92 0 1.667-.746 1.667-1.667V6.667C35 5.747 34.254 5 33.333 5h-4.561c-.92 0-1.667.746-1.667 1.667v26.666c0 .92.746 1.667 1.667 1.667Z"
        fill="#FC2D4C"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-marketing_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryMarketing;

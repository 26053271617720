/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddNewsletterProviderCommand } from '../models/AddNewsletterProviderCommand';
import type { BasicResponseAnalysisItems } from '../models/BasicResponseAnalysisItems';
import type { BasicResponseFindMembersResult } from '../models/BasicResponseFindMembersResult';
import type { BasicResponseGetEvaluationCategoriesResult } from '../models/BasicResponseGetEvaluationCategoriesResult';
import type { BasicResponseGetEvaluationItemResult } from '../models/BasicResponseGetEvaluationItemResult';
import type { BasicResponseGetMemberEvaluationResult } from '../models/BasicResponseGetMemberEvaluationResult';
import type { BasicResponseGetMemberResult } from '../models/BasicResponseGetMemberResult';
import type { BasicResponseGetSmartPlaceItem } from '../models/BasicResponseGetSmartPlaceItem';
import type { BasicResponseGetSmartPlaceResult } from '../models/BasicResponseGetSmartPlaceResult';
import type { BasicResponseGetSmartPlacesResult } from '../models/BasicResponseGetSmartPlacesResult';
import type { BasicResponseGetSmartStoreItem } from '../models/BasicResponseGetSmartStoreItem';
import type { BasicResponseGetSmartStoreResult } from '../models/BasicResponseGetSmartStoreResult';
import type { BasicResponseGetSmartStoresResult } from '../models/BasicResponseGetSmartStoresResult';
import type { BasicResponseGetSnsChannelBlogDetailResult } from '../models/BasicResponseGetSnsChannelBlogDetailResult';
import type { BasicResponseGetSnsChannelBlogSearchResult } from '../models/BasicResponseGetSnsChannelBlogSearchResult';
import type { BasicResponseGetSnsChannelInstagramDetailResult } from '../models/BasicResponseGetSnsChannelInstagramDetailResult';
import type { BasicResponseGetSnsChannelInstagramSearchResult } from '../models/BasicResponseGetSnsChannelInstagramSearchResult';
import type { BasicResponseListGetAnalysisItem } from '../models/BasicResponseListGetAnalysisItem';
import type { BasicResponseListGetGrowthChangeItem } from '../models/BasicResponseListGetGrowthChangeItem';
import type { BasicResponseListNewsletterCategoryDetail } from '../models/BasicResponseListNewsletterCategoryDetail';
import type { BasicResponseListNewsletterProviderListItem } from '../models/BasicResponseListNewsletterProviderListItem';
import type { BasicResponseListSnsChannelKeyword } from '../models/BasicResponseListSnsChannelKeyword';
import type { BasicResponseNewsletterProviderDetail } from '../models/BasicResponseNewsletterProviderDetail';
import type { BasicResponseUUID } from '../models/BasicResponseUUID';
import type { BasicResponseVoid } from '../models/BasicResponseVoid';
import type { FindMembersQuery } from '../models/FindMembersQuery';
import type { GetEvaluationItemQuery } from '../models/GetEvaluationItemQuery';
import type { GetMemberEvaluationQuery } from '../models/GetMemberEvaluationQuery';
import type { GetSmartPlaceQuery } from '../models/GetSmartPlaceQuery';
import type { GetSmartStoreQuery } from '../models/GetSmartStoreQuery';
import type { GetSnsChannelSearchDetailQuery } from '../models/GetSnsChannelSearchDetailQuery';
import type { GetSnsChannelSearchQuery } from '../models/GetSnsChannelSearchQuery';
import type { ModifyNewsletterProviderCommand } from '../models/ModifyNewsletterProviderCommand';
import type { SetEvaluationItemQuery } from '../models/SetEvaluationItemQuery';
import type { SetMemberQuery } from '../models/SetMemberQuery';
import type { SetSnsChannelBlogQuery } from '../models/SetSnsChannelBlogQuery';
import type { SetSnsChannelInstagramQuery } from '../models/SetSnsChannelInstagramQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class Service {

    /**
     * 사용자 현황 조회
     * 백오피스/사용자 관리/사용자 현황
     * @returns BasicResponseGetMemberResult OK
     * @throws ApiError
     */
    public static getMember1({
        memberId,
    }: {
        memberId: string,
    }): CancelablePromise<BasicResponseGetMemberResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/member/{memberId}',
            path: {
                'memberId': memberId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 정보 저장
     * 백오피스/사용자 관리/사용자 현황
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static setMember({
        memberId,
        requestBody,
    }: {
        memberId: string,
        requestBody: SetMemberQuery,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/member/{memberId}',
            path: {
                'memberId': memberId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 별 평가 항목표 저장
     * 백오피스/사용자 관리/사용자 현황
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static setMemberEvaluation({
        requestBody,
    }: {
        requestBody: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/member/evaluation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 별 평가 항목표 목록
     * 백오피스/사용자 관리/사용자 현황
     * @returns BasicResponseGetMemberEvaluationResult OK
     * @throws ApiError
     */
    public static getMemberEvaluation({
        requestBody,
    }: {
        requestBody: GetMemberEvaluationQuery,
    }): CancelablePromise<BasicResponseGetMemberEvaluationResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/member/evaluation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 성장 추이 정보 - 타이틀 수정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static setMemberTitleGrowthTitle({
        title,
        type,
    }: {
        title: string,
        type: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/growthchange/title',
            query: {
                'title': title,
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 평가 항목 등록
     * 백오피스/사용자 관리/평가표
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static insertItem({
        requestBody,
    }: {
        requestBody: SetEvaluationItemQuery,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/evaluation/item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 평가 항목 삭제
     * 백오피스/사용자 관리/평가표
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteItem({
        id,
    }: {
        id: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/evaluation/item',
            query: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 평가 항목 수정
     * 백오피스/사용자 관리/평가표
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static updateItem({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: SetEvaluationItemQuery,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/evaluation/item/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 뉴스레터 제공자 상세조회
     * 뉴스레터 제공자 삭제
     * @returns BasicResponseNewsletterProviderDetail OK
     * @throws ApiError
     */
    public static getNewsletterProvider({
        newsletterProviderId,
    }: {
        newsletterProviderId: string,
    }): CancelablePromise<BasicResponseNewsletterProviderDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-admin/newsletter-providers/{newsletter-provider-id}',
            path: {
                'newsletter-provider-id': newsletterProviderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 뉴스레터 제공자 수정
     * 뉴스레터 제공자 수정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyNewsletterProvider({
        newsletterProviderId,
        requestBody,
    }: {
        newsletterProviderId: string,
        requestBody: ModifyNewsletterProviderCommand,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-admin/newsletter-providers/{newsletter-provider-id}',
            path: {
                'newsletter-provider-id': newsletterProviderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 뉴스레터 제공자 삭제
     * 뉴스레터 제공자 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteNewsletterProvider({
        newsletterProviderId,
    }: {
        newsletterProviderId: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/binder-admin/newsletter-providers/{newsletter-provider-id}',
            path: {
                'newsletter-provider-id': newsletterProviderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 크롤링 데이터 - 인스타그램 상세 데이터 저장 처리
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static setSnsChannelInstagram({
        requestBody,
    }: {
        requestBody: SetSnsChannelInstagramQuery,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/backoffice/snschannel/instagram',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 크롤링 데이터 - 블로그 상세 데이터 저장 처리
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static setSnsChannelBlog({
        requestBody,
    }: {
        requestBody: SetSnsChannelBlogQuery,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/backoffice/snschannel/blog',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 종합 분석 정보 - 타이틀 수정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static setMemberTitleAnalysis({
        title,
        type,
    }: {
        title: string,
        type: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/analysis/title',
            query: {
                'title': title,
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 스마트 스토어 현황 조회
     * 백오피스/사용자 관리/스마트 스토어
     * @returns BasicResponseGetSmartStoresResult OK
     * @throws ApiError
     */
    public static getSmartStores({
        requestBody,
    }: {
        requestBody: GetSmartStoreQuery,
    }): CancelablePromise<BasicResponseGetSmartStoresResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/smartstore/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 스마트 플레이스 현황 조회
     * 백오피스/사용자 관리/스마트 플레이스
     * @returns BasicResponseGetSmartPlacesResult OK
     * @throws ApiError
     */
    public static getSmartPlaces({
        requestBody,
    }: {
        requestBody: GetSmartPlaceQuery,
    }): CancelablePromise<BasicResponseGetSmartPlacesResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/smartplace/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 현황 목록 조회
     * 백오피스/사용자 관리/사용자 현황
     * @returns BasicResponseFindMembersResult OK
     * @throws ApiError
     */
    public static findMembers({
        requestBody,
    }: {
        requestBody: FindMembersQuery,
    }): CancelablePromise<BasicResponseFindMembersResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/members/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 평가표 사용자 결과
     * 백오피스/사용자 관리/평가표
     * @returns BasicResponseGetEvaluationItemResult OK
     * @throws ApiError
     */
    public static getEvaluationItems({
        requestBody,
    }: {
        requestBody: GetEvaluationItemQuery,
    }): CancelablePromise<BasicResponseGetEvaluationItemResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/evaluation/items',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 뉴스레터 제공자 리스트 조회
     * 뉴스레터 제공자 리스트 조회
     * @returns BasicResponseListNewsletterProviderListItem OK
     * @throws ApiError
     */
    public static getNewsletterProviders({
        display,
        start,
    }: {
        /**
         * 페이지 사이즈 (1 ~ 100 사이의 값)
         */
        display?: number,
        /**
         * 페이지 번호 (1부터 시작)
         */
        start?: number,
    }): CancelablePromise<BasicResponseListNewsletterProviderListItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-admin/newsletter-providers',
            query: {
                'display': display,
                'start': start,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 뉴스레터 제공자 등록
     * 뉴스레터 제공자 등록
     * @returns BasicResponseUUID OK
     * @throws ApiError
     */
    public static addNewsletterProvider({
        requestBody,
    }: {
        requestBody: AddNewsletterProviderCommand,
    }): CancelablePromise<BasicResponseUUID> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binder-admin/newsletter-providers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 크롤링 데이터 - 인스타그램 관리 현황 조회
     * @returns BasicResponseGetSnsChannelInstagramSearchResult OK
     * @throws ApiError
     */
    public static getSnsChannelInstagramSEarchResult({
        requestBody,
    }: {
        requestBody: GetSnsChannelSearchQuery,
    }): CancelablePromise<BasicResponseGetSnsChannelInstagramSearchResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/snschannel/instagram/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 크롤링 데이터 - 인스타그램 관리 상세 현황 조회
     * @returns BasicResponseGetSnsChannelInstagramDetailResult OK
     * @throws ApiError
     */
    public static getSnsChannelINstagramList({
        requestBody,
    }: {
        requestBody: GetSnsChannelSearchDetailQuery,
    }): CancelablePromise<BasicResponseGetSnsChannelInstagramDetailResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/snschannel/instagram/detail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 크롤링 데이터 - 블로그 관리 현황 조회
     * @returns BasicResponseGetSnsChannelBlogSearchResult OK
     * @throws ApiError
     */
    public static getSnsChannelBlogSearchResult({
        requestBody,
    }: {
        requestBody: GetSnsChannelSearchQuery,
    }): CancelablePromise<BasicResponseGetSnsChannelBlogSearchResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/snschannel/blog/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 크롤링 데이터 - 블로그 관리 상세 현황 조회
     * @returns BasicResponseGetSnsChannelBlogDetailResult OK
     * @throws ApiError
     */
    public static getSnsChannelBlogList({
        requestBody,
    }: {
        requestBody: GetSnsChannelSearchDetailQuery,
    }): CancelablePromise<BasicResponseGetSnsChannelBlogDetailResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/snschannel/blog/detail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 종합 분석 정보 (v2)
     * @returns BasicResponseAnalysisItems OK
     * @throws ApiError
     */
    public static getAnalysisItemsV2(): CancelablePromise<BasicResponseAnalysisItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/total-analysis',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 종합 분석 정보 (개선)
     * @returns BasicResponseListGetAnalysisItem OK
     * @throws ApiError
     */
    public static getAnalysisItems(): CancelablePromise<BasicResponseListGetAnalysisItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/total-analysis',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 로그인 사용자 스마트 스토어 분석 정보
     * 로그인 사용자 스마트 스토어 분석 정보
     * @returns BasicResponseGetSmartStoreItem OK
     * @throws ApiError
     */
    public static getSmartStoreItem(): CancelablePromise<BasicResponseGetSmartStoreItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/smartstore',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 스마트 스토어 현황 상세 조회
     * 백오피스/사용자 관리/스마트 스토어
     * @returns BasicResponseGetSmartStoreResult OK
     * @throws ApiError
     */
    public static getSmartStore({
        smartStoreId,
    }: {
        smartStoreId: string,
    }): CancelablePromise<BasicResponseGetSmartStoreResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/smartstore/{smartStoreId}',
            path: {
                'smartStoreId': smartStoreId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 로그인 사용자 스마트 플레이스 분석 정보
     * 로그인 사용자 스마트 스토어 분석 정보
     * @returns BasicResponseGetSmartPlaceItem OK
     * @throws ApiError
     */
    public static getSmartPlaceItem(): CancelablePromise<BasicResponseGetSmartPlaceItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/smartplace',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 스마트 플레이스 현황 상세 조회
     * 백오피스/사용자 관리/스마트 플레이스
     * @returns BasicResponseGetSmartPlaceResult OK
     * @throws ApiError
     */
    public static getSmartPlace({
        smartPlaceId,
    }: {
        smartPlaceId: string,
    }): CancelablePromise<BasicResponseGetSmartPlaceResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/smartplace/{smartPlaceId}',
            path: {
                'smartPlaceId': smartPlaceId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 현황 조회
     * 백오피스/사용자 관리/사용자 현황
     * @returns BasicResponseGetMemberResult OK
     * @throws ApiError
     */
    public static getMember({
        memberId,
    }: {
        memberId: string,
    }): CancelablePromise<BasicResponseGetMemberResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/members/{memberId}',
            path: {
                'memberId': memberId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 성장 추이 정보 (개선)
     * @returns BasicResponseListGetGrowthChangeItem OK
     * @throws ApiError
     */
    public static getGrowthChangeItems(): CancelablePromise<BasicResponseListGetGrowthChangeItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/growth-change',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 평가표 카테고리
     * 백오피스/사용자 관리/평가표
     * @returns BasicResponseGetEvaluationCategoriesResult OK
     * @throws ApiError
     */
    public static getEvaluationCategories(): CancelablePromise<BasicResponseGetEvaluationCategoriesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/evaluation/categories',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 뉴스레터 카테고리 리스트
     * 뉴스레터 카테고리 리스트
     * @returns BasicResponseListNewsletterCategoryDetail OK
     * @throws ApiError
     */
    public static getNewsletterCategories1(): CancelablePromise<BasicResponseListNewsletterCategoryDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-admin/newsletter-categories',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 크롤링 데이터 - 일일 크롤링 처리 키워드 목록
     * @returns BasicResponseListSnsChannelKeyword OK
     * @throws ApiError
     */
    public static getCrawlingKeywordList({
        type,
    }: {
        type: string,
    }): CancelablePromise<BasicResponseListSnsChannelKeyword> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/backoffice/snschannel/keyword/{type}',
            path: {
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}

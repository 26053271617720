import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryAll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-all_svg__a)">
      <path
        d="M10.733 34.013c4.27 0 7.733-6.274 7.733-14.013 0-7.74-3.462-14.013-7.733-14.013C6.463 5.987 3 12.261 3 20c0 7.74 3.462 14.013 7.733 14.013ZM29.267 34.013C33.538 34.013 37 27.74 37 20c0-7.74-3.462-14.013-7.733-14.013-4.27 0-7.733 6.274-7.733 14.013 0 7.74 3.462 14.013 7.733 14.013Z"
        fill="#E5E8EB"
      />
      <path
        d="M8.75 23.907a3.907 3.907 0 1 0 0-7.814 3.907 3.907 0 0 0 0 7.814ZM27.704 23.907a3.907 3.907 0 1 0 0-7.814 3.907 3.907 0 0 0 0 7.814Z"
        fill="#191F28"
      />
      <path
        d="M7.154 19.564a.934.934 0 1 0 0-1.868.934.934 0 0 0 0 1.868ZM26.21 19.564a.934.934 0 1 0 0-1.868.934.934 0 0 0 0 1.868Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-all_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryAll;

import React, { useEffect } from 'react';
import '@src/assets/styles/taboola.scss';

declare global {
  interface Window {
    _taboola: unknown[];
  }
}

const TaboolaWidget: React.FC = () => {
  useEffect(() => {
    // window._taboola가 이미 정의되어 있는지 확인
    window._taboola = window._taboola || [];

    // Taboola 스크립트 푸시
    window._taboola.push({
      mode: 'thumbnails-a',
      container: 'taboola-thumbnails-a',
      placement: 'Below Article Thumbnails',
      target_type: 'mix',
    });
  }, []);

  return <div id="taboola-thumbnails-a"></div>;
};

export default TaboolaWidget;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryHobby = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-hobby_svg__a)">
      <path
        d="M27.733 23.813c.122-.188.236-.385.343-.591 1.334-2.562.995-5.914-.904-8.457-3.15-4.216-8.875-4.851-12.09-1.637a6.513 6.513 0 0 0-.29.31c-.94 1.07-2.31 1.623-3.79 1.741-2.516.202-4.899 1.239-6.708 3.136C.317 22.485.62 29.596 4.98 34.283c4.782 5.141 12.434 5.66 16.86 1.234a10.145 10.145 0 0 0 2.684-4.801c.616-2.52 1.826-4.775 3.209-6.904v.001Z"
        fill="#FA0"
      />
      <path
        d="M31.614 6.223 20.51 17.328l2.145 2.145L33.76 8.37l-2.146-2.146Z"
        fill="#3D3D3D"
      />
      <path
        d="m14.309 29.358-3.685-3.685a.811.811 0 1 0-1.147 1.147l3.685 3.685a.811.811 0 0 0 1.147-1.147Z"
        fill="#F77F00"
      />
      <path
        d="M19.397 24.117a3.532 3.532 0 1 0 0-7.064 3.532 3.532 0 0 0 0 7.064Z"
        fill="#3D3D3D"
      />
      <path
        d="m31.665 5.203 3.023-3.446a.81.81 0 0 1 1.18-.039l2.396 2.396a.808.808 0 0 1-.04 1.18L34.78 8.317a.81.81 0 0 1-1.106-.036L31.7 6.308a.81.81 0 0 1-.036-1.106v.001Z"
        fill="#FA0"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-hobby_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryHobby;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 72 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m11.482 24.115 24.378-.001h24.183v28.887a3.549 3.549 0 0 1-3.551 3.547H15.035A3.549 3.549 0 0 1 11.484 53l-.002-28.886ZM35.787 34.312 12.6 55.178M35.765 34.312l23.15 20.862"
      stroke="#080808"
      strokeWidth={1.582}
    />
    <path
      d="M11.024 23.621 32.791 8.862a5.47 5.47 0 0 1 6.153.01l21.598 14.746-21.479 15.529a5.47 5.47 0 0 1-6.392.01L11.024 23.622Z"
      fill="#080808"
    />
    <mask
      id="ico-newsletter_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={12}
      y={8}
      width={47}
      height={33}
    >
      <path
        d="M32.906 39.336 12.758 24.864 15.49 8.522h38.624l4.72 16.33-20.012 14.475a5.06 5.06 0 0 1-5.916.01Z"
        fill="#F7C3D7"
      />
    </mask>
    <g mask="url(#ico-newsletter_svg__a)">
      <path
        d="M51.385 13.148H21.423a2.372 2.372 0 0 0-2.373 2.37v27.32c0 1.308 1.063 2.37 2.373 2.37h29.962a2.372 2.372 0 0 0 2.373-2.37v-27.32c0-1.309-1.062-2.37-2.373-2.37Z"
        fill="#FFC121"
      />
    </g>
    <path
      d="M25.344 20.075h13.724M25.344 24.4H46.23M25.344 28.844H46.23"
      stroke="#fff"
      strokeWidth={1.582}
      strokeLinecap="round"
    />
    <path
      d="m63.825 16.145 5.225 3.012M67.945 15.042 64.93 20.26M8.19 5.41l6.113 7.474M16.405 4.942l.987 6.06M6.086 13.358l5.75 2.177"
      stroke="#080808"
      strokeWidth={1.582}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgIcoNewsletter;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewsletterCategoryFinance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-newsletter-category-finance_svg__a)">
      <path
        d="m21.503 13.843-4.987-4.395a1.633 1.633 0 0 0-2.16 0L4.22 18.381c-.352.31-.553.756-.553 1.225v12.471a3.267 3.267 0 0 0 3.267 3.267h26.133a3.267 3.267 0 0 0 3.267-3.267V6.293c0-1.406-1.658-2.155-2.713-1.225l-9.957 8.775a1.635 1.635 0 0 1-2.161 0Z"
        fill="#FDAEB3"
      />
      <path
        d="m33.62 5.068-9.957 8.775a1.633 1.633 0 0 1-2.16 0l-4.987-4.395a1.633 1.633 0 0 0-2.16 0L4.22 18.38c-.352.31-.553.757-.553 1.226v4.263l10.689-9.421a1.633 1.633 0 0 1 2.16 0l4.987 4.395a1.633 1.633 0 0 0 2.16 0l12.67-11.166V6.294c0-1.406-1.658-2.155-2.713-1.226Z"
        fill="#EF4452"
      />
    </g>
    <defs>
      <clipPath id="ico-newsletter-category-finance_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoNewsletterCategoryFinance;
